@import "../../../Config/variables";

.modal-start-simulation{
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  background-color: rgba(0, 0, 0, 0.20);
  align-items: center;
  justify-content: center;

  .modal-content{
    background-color: #fff;
    width: 70vw;
    max-width: 400px;
    height: 35vh;
    min-height: 260px;
    max-height: 300px;
    border-radius: 10px;
    border: 1px solid #d4d4d4;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  h4{
    font-size: 28px;
    margin: 0 10px;
    text-align: center;
    margin-bottom: 0;
  }

  button{
    background-color: $accent500;
    border: 0;
    width: 80%;
    height: 60px;
    color: #fff;
    border-radius: 8px;
    text-align: center;
    margin-top: 10px;
    transition: 300ms;
    font-weight: 600;
    cursor: pointer;

    &:hover{
      background-color: $accent400;
    }

    &.grey{
      background-color: #e0e0e0;
      border: 1px solid #b3b3b3;
      color: #000;
      margin-bottom: 10px;

      &:hover{
        background-color: #f5f5f5 !important;
      }
    }
  }

}