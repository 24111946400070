@import "../../Config/variables";

.arrow{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;

  &.left-arrow{
    button{
      background-color: rgb(212, 212, 212);
    }
  }

  &.right-arrow{
    button{
      background-color: $accent500;

      &:hover:not(&.disable){
        background-color: $accent400 !important;
        transition: 300ms;
      }
    }
  }

  button{

    &.disable{
      opacity: 0.4;
    }

    cursor: pointer;
    transition: 300ms;
    width: 70px;
    height: 70px;
    border-radius: 100px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}