@font-face {
  font-family: "Avenir Next";
  src: url("./Fonts/Avenir_Next/AvenirNextLTPro-Regular.otf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Avenir Next";
  src: url("./Fonts/Avenir_Next/AvenirNextLTPro-Bold.otf") format("truetype");
  font-weight: 600;
}

*{
  font-family: "Avenir Next";
  box-sizing: border-box;
}

.only-display-phone{
  display: none;
}

@media only screen and (max-width: 830px){
  .no-display-phone{
    display: none;
  }

  .only-display-phone{
    display: flex !important;
  }
}