@import "../Config/variables";

.arrows-phone{
    display: flex;
    height: 75px;
    background-color: #fff;

    width: 100vw;
    position: absolute;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    padding: 0 5%;
    border-top: 1px solid #e6e3dd;

    .arrow-phone{
        flex-shrink: 0;
        flex-grow: 1;
        flex-basis: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        border-radius: 32px;
        font-weight: 600;
        font-size: 17px;
        transition: 300ms;
        cursor: pointer;

        &:last-child{
            margin-left: 5%;
        }

        &.right-arrow-phone{

            &.activate{
                background-color: $accent500;
                color: #fff;
            }

            &.disable{
                background-color: $accent400;
                color: #fff;
            }

            svg{
                margin-left: 7px;
            }
        }

        &.left-arrow-phone{
            text-decoration: underline;

            svg{
                margin-right: 7px;
            }
        }
    }
}