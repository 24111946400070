@import "../../Config/variables";

@media only screen and (max-width: 830px){
  .header-simulation{
    .logo-lexem{
      height: 60px !important;
      width: 80px !important;
    }

    h1{
      font-size: 16px !important;
    }
  }

  .background{
    width: 100vw;
    height: 10px;
    background-color: rgba(163, 163, 163, 0.50);
  }

  .foreground{
    width: 10%;
    height: 100%;
    background-color: $accent500;
    content: " ";
  }
}

.header-simulation{
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
  height: 75px;
  width: 100vw;
  padding: 8px 8px 0px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  
  .logo-lexem{
    height: 70px;
    width: 100px;
  }

  h1{
    font-size: 18px;
    text-align: center;
    font-weight: 400;
  }

  .simulateur-name{
    color: $accent500;
    font-weight: 600;
  }

  & > *{
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: 0;
  }
}