@media only screen and (max-width: 830px){

  .page{
    .page-container{
      width: 100% !important;
    }

    .title{
      font-size: 20px !important;
    }

    .subtitle{
      font-size: 15px;
    }
  }
}

.page{
  padding: 20px 7vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .left-arrow, .right-arrow{
    width: 24vw;
  }

  .page-container{
    width: 52vw;
  }

  .title{
    font-size: 24px;
  }
}