@import "../../../../Config/variables";

.input-container{

  .input-borders{
    display: flex;
    align-items: center;
    justify-content: center;

    svg{
      margin-right: 10px;
    }
  }

  input{
    width: 100%;
    height: 40px;
    outline: 0;
    border: 0;
    font-size: 22px;
    padding: 5px 7px;
    border-bottom: 2px solid black;
    transition: 400ms;

  }

  input:focus{
    border-bottom: 2px solid $accent500;
    transition: 400ms;
  }
}