@import "../../Config/variables";

.nav-container{
  margin: 3vh 7vw 0vh 7vw;

  nav{
    display: flex;

    & > *{
      flex-shrink: 0;
      flex-grow: 1;
      flex-basis: 0;
    }

    & > .category{
      text-align: center;
    }

    & > .category.selected{
      text-align: center;
      color: $accent500;
      font-weight: 600;
    }
  }

  .progress-bar-container{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .background{
    width: 100%;
    content: '';
    height: 5px;
    background-color: rgba(163, 163, 163, 0.50);

    .foreground{
      content: '';
      height: 100%;
      background-color: $accent500;
    }

  }

  .dot{
    background-color: white;
    width: 21px;
    height: 21px;
    content: '';
    flex-shrink: 0;
    border-radius: 100%;
    border: 3px solid rgba(163, 163, 163, 0.50);

    &.selected{
      background-color: $accent500;
      border-color: $accent500;
      display: flex;
      align-items: center;
      justify-content: center;

      svg{
        margin-top: 2px;
      }
    }
  }
}

