@import "../../../../Config/variables";

.optin{

    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;

    .text{
        font-size: 17px;
        margin-left: 10px;
    }

    .switch {
        flex-shrink: 0;
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }
    
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    
    
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 34px;
    }
    
    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
    
    }
    
    input:checked + .slider {
        background-color: $accent500;
    }
    
    input:focus + .slider {
        box-shadow: 0 0 1px $accent500;
    }
    
    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
}