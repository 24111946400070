@import "../../Config/variables";

.loader-container.disable{
  top: -100vh;
  transition: 1s;
}

.loader-container.active{
  top: 0;
  left: 0;
  transition: 1s;
}

.loader-container {
  .loader {
    margin: 0 0 2em;
    height: 100px;
    width: 20%;
    text-align: center;
    padding: 1em;
    margin: 0 auto 1em;
    display: inline-block;
    vertical-align: top;
    
  }

  svg{
      height: 150px;
      width: 150px;
      margin-bottom: 50px;
  }

  svg path,
  svg rect {
    fill: #fff;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: $accent500;
  text-align: center;

  .text{
      color: #fff;
      font-weight: 600;

      .big{
          font-size: 26px;
      }

      font-size: 18px;
  }
}
