@import "../../../../Config/variables";

.birthdate-container{
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 25px;
  grid-row-gap: 0px;

  label{
    // font-weight: 600;
    // text-decoration: underline;
    font-size: 18px;
  }

  input{
    width: 100%;
    height: 40px;
    outline: 0;
    border: 0;
    font-size: 22px;
    padding: 5px 7px;
    border-bottom: 2px solid black;
    transition: 400ms;

  }

  input:focus{
    border-bottom: 2px solid $accent500;
    transition: 400ms;
  }
}