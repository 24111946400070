@import "../../Config/variables";

.card{
    border-radius: 20px;
    height: 120px;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.blue{
        background-color: $accent500;
        color: #fff;
        font-weight: 600;

        .numerous{
            font-size: 25px;
            margin-bottom: 5px;
        }

        .text{
            font-size: 15px;
        }
    }
}